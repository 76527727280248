/* eslint-disable react/prop-types */
import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormHelperText from '@mui/material/FormHelperText';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import StarIcon from '@mui/icons-material/StarBorder';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {
  InputAdornment,
  TextField,
} from '@mui/material';
import { blueGrey } from '../../utils/brandColors';
import HorseIcon from '../CustomIcons/Horse'

const tiers = [
  {
    title: 'Horse Management',
    subheader: '(Pay Per Horse)',
    priceMonthly: '$2.20',
    priceAnnual: '$1.98',
    description: [
      'Pay per horse (up to 50)',
      '3 Additional Users (Staff)',
      'Horse and Record Management',
      'Task Planning and Operations',
      'Digital and Configurable Barn Board',
      'Calendar Management, Integration and Scheduling',
    ],
    buttonText: 'Start Free Trial',
    buttonVariant: 'outlined',
    selectPlan: (billing) => (billing === 'monthly'
      ? env.stripe_basic_monthly_plan
      : env.stripe_basic_annual_plan),
  },
  {
    title: 'Professional - Barn Management',
    subheader: 'Most Popular',
    priceMonthly: '$199',
    priceAnnual: '$179',
    description: [
      'Everything from Horse Management',
      '100 Horses Included',
      'Unlimited Users (Staff & Clients)',
      'Client Experience (Mobile Access)',
      'Organization/Staff Chat',
      'Announcements & Activity Feed',
      'Onboarding, Setup & Prioritized Support',
    ],
    buttonText: 'Start Free Trial',
    buttonVariant: 'contained',
    selectPlan: (billing) => (billing === 'monthly'
      ? env.stripe_barn_management_monthly_plan
      : env.stripe_barn_management_annual_plan),
  },
  // {
  //   title: 'Premium (Enterprise)',
  //   priceMonthly: 'Contact Us',
  //   priceAnnual: 'Contact Us',
  //   description: [
  //     'Everything from Pro Plan',
  //     'Unlimited Horses & Users',
  //     'Prioritized Feature Requests & Support',
  //     'Competition & Show Mobile App',
  //     'Private Chat Group with Questri Owners',
  //   ],
  //   buttonText: 'Get In Touch',
  //   buttonVariant: 'outlined',
  //   selectPlan: (billing) => (billing === 'monthly'
  //     ? env.stripe_client_access_monthly_plan
  //     : env.stripe_client_access_annual_plan),
  // },
];

const PricingContent = ({
  onSelectPlan,
  onChangeBilling,
  hideCallToActionButtons = false,
  stripeCheckout = false,
  tenant,
}) => {
  const [billing, setBilling] = React.useState('monthly');
  const [items, setItems] = React.useState(1);

  const getBasicPrice = (tier) => {
    let total = 0;
    const price = billing === 'monthly'
      ? parseFloat(tier.priceMonthly.replace('$', ''), 10)
      : parseFloat(tier.priceAnnual.replace('$', ''), 10);
    for (let i = 0; i < items; i += 1) {
      // We removed the tiered pricing for a cheaper plan to get people in the door - keeping here incase we bring it back
      // const horseMultiple = (i <= 9 && 1) || (i <= 24 && 0.75) || 0.625;
      total += (price * 1)
    }
    return total.toFixed(2);
  }
  return (
    <Box>
      <Container
        disableGutters
        maxWidth="sm"
        component="main"
        sx={{
          pt: 4,
          pb: 2,
        }}
      >
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="primary"
          sx={{ fontSize: 38 }}
          gutterBottom
        >
          Questri Pricing
        </Typography>
        <Typography variant="h5" align="center" color="text.secondary" component="p">
          Unsure of which plan to pick? We are always happy to talk through our offerings
          and what could best fit your business. Contact us at
          <Button
            sx={{ marginLeft: '2px' }}
            color="primary"
            size="small"
            href="mailto:support@questri.app?Subject=Information%20On%20Questri%20Plans"
            target="_top"
          >
            support@questri.app
          </Button>.
        </Typography>
      </Container>
      {/* End hero unit */}
      <Container maxWidth="md" component="main">
        <Grid item xs={12} align="center">
          <FormControl
            component="fieldset"
            variant="outlined"
            sx={{
              textAlign: 'center',
              mb: 0.5,
            }}
          >
            <Typography variant="h5" component="div">Pay:
              <FormHelperText component="div">
                <Typography sx={{ fontWeight: 300 }}>
                  You save 10% when paying annually
                </Typography>
              </FormHelperText>
            </Typography>
            <RadioGroup
              row
              aria-label="Billing Period"
              name="billing-period-length"
              value={billing}
              onChange={(e) => { setBilling(e.target.value); }}
              sx={{ justifyContent: 'center' }}
            >
              <FormControlLabel value="monthly" control={<Radio />} label="Monthly" />
              <FormControlLabel value="annual" control={<Radio />} label="Annually" />
            </RadioGroup>
          </FormControl>

        </Grid>
        <Grid container spacing={5} alignItems="flex-end">
          {tiers.map((tier) => (
            // Enterprise card is full width at sm breakpoint
            <Grid
              item
              key={tier.title}
              xs={12}
              sm={6}
              md={6}
              sx={{ height: '100%' }}
            >
              <Card sx={{ height: '100%' }}>
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: 'center' }}
                  action={tier.title === 'Professional - Barn Management'
                    ? <StarIcon sx={{ color: '#FF6600' }} />
                    : null}
                  subheaderTypographyProps={{
                    align: 'center',
                    color: '#FF6600',
                    fontWeight: 500,
                  }}
                  sx={{
                    backgroundColor: (theme) => (theme.palette.mode === 'light'
                      ? blueGrey[100]
                      : blueGrey[700]),
                  }}
                />
                <CardContent sx={{ height: '100%' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'baseline',
                      mb: 2,
                    }}
                  >
                    {
                      tier.title === 'Horse Management'
                        ? (
                          <Typography
                            component="h2"
                            variant="h3"
                            color="primary"
                            sx={{ fontSize: '2.5rem' }}
                          >
                            ${getBasicPrice(tier)}
                          </Typography>
                        )
                        : (
                          <Typography
                            component="h2"
                            variant="h3"
                            color="primary"
                            sx={{ fontSize: '2.5rem' }}
                          >
                            {billing === 'monthly'
                              ? tier.priceMonthly
                              : tier.priceAnnual}
                          </Typography>
                        )
                    }
                    {tier.priceMonthly !== 'Contact Us' && (
                      <Typography variant="h6" color="secondary">
                        /mo
                      </Typography>
                    )}
                  </Box>
                  {
                    tier.title === 'Horse Management' && (
                      <Box sx={{ textAlign: 'center' }}>
                        <TextField
                          sx={{
                            ml: 2,
                            width: 100,
                          }}
                          size="small"
                          value={parseInt(items, 10)}
                          InputProps={{
                            startAdornment: <InputAdornment position="start"><HorseIcon /></InputAdornment>,
                            inputMode: 'numeric',
                            type: 'number',
                            max: 50,
                            min: 1,
                          }}
                          onChange={(e) => {
                            const num = parseInt(e.target.value, 10); setItems(num > 50
                              ? 50
                              : num);
                          }}
                          label="Horses"
                        />
                      </Box>
                    )
                  }
                  <ul
                    style={{
                      padding: '0.5rem',
                      height: 250,
                    }}
                  >
                    {tier.description.map((line) => (
                      <Typography
                        component="li"
                        variant="subtitle1"
                        align="center"
                        key={line}
                        sx={{
                          lineHeight: '1.15',
                          mb: 2,
                        }}
                      >
                        {line}
                      </Typography>
                    ))}
                  </ul>
                </CardContent>
                {
                  !hideCallToActionButtons && (
                    <CardActions>
                      {
                        (stripeCheckout && tenant)
                          ? (
                            <form
                              method="POST"
                              action="/create-checkout-session"
                              style={{
                                textAlign: 'center',
                                width: '100%',
                              }}
                            >
                              <input style={{ display: 'none' }} type="text" id="customerId" name="customerId" defaultValue={tenant && tenant.stripeCustomerId} />
                              <input style={{ display: 'none' }} type="text" id="price" name="price" value={tier.selectPlan(billing)} />
                              <input style={{ display: 'none' }} type="text" id="quantity" name="quantity" value={(items && items > 50 && 50) || (items && items < 1 && 1) || items || 1} />
                              <input style={{ display: 'none' }} type="text" id="successUrl" name="successUrl" defaultValue={window.location.href} />
                              <input style={{ display: 'none' }} type="text" id="cancelUrl" name="cancelUrl" defaultValue={window.location.href} />
                              <input style={{ display: 'none' }} type="text" id="tenant" name="tenant" defaultValue={tenant.name} />
                              <input style={{ display: 'none' }} type="text" id="authorization" name="authorization" defaultValue={`bearer ${localStorage.id_token}`} />
                              {
                                (tier.title === 'Premium (Client Experience)')
                                  ? (
                                    <Button
                                      fullWidth
                                      variant={tier.buttonVariant}
                                      onClick={() => {
                                        window.location.href = 'mailto:support@questri.app?Subject=Premium%20Questri%20Plan%20Interest';
                                      }}
                                    >
                                      Contact Us
                                    </Button>
                                  )
                                  : (
                                    <Box>

                                      <Typography
                                        variant="caption"
                                        sx={{
                                          fontWeight: 300,
                                          lineHeight: '12px',
                                        }}
                                        component="div"
                                        align="center"
                                        gutterBottom
                                      >
                                        You will be redirected to our secure billing portal to complete the transaction.
                                      </Typography>
                                      <Button
                                        fullWidth
                                        variant={tier.buttonVariant}
                                        type="submit"
                                      >
                                        Subscribe
                                      </Button>

                                    </Box>

                                  )
                              }
                            </form>
                          )
                          : (
                            <Button
                              fullWidth
                              variant={tier.buttonVariant}
                              onClick={() => {
                                if (tier.title === 'Premium (Client Experience)') {
                                  window.location.href = 'mailto:support@questri.app?Subject=Premium%20Questri%20Plan%20Interest';
                                } else {
                                  onChangeBilling(billing);
                                  onSelectPlan(tier.selectPlan(billing));
                                }
                              }}
                            >
                              {tier.buttonText}
                            </Button>
                          )
                      }
                    </CardActions>
                  )
                }
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  )
}

export default PricingContent;
